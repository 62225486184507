import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "home",
  class: "home-container"
}
const _hoisted_2 = { class: "search-section" }
const _hoisted_3 = { class: "search-box" }
const _hoisted_4 = { class: "laws-list" }
const _hoisted_5 = { class: "category-header" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "laws-grid" }
const _hoisted_8 = { class: "law-header" }
const _hoisted_9 = { class: "law-id" }
const _hoisted_10 = { class: "law-badges" }
const _hoisted_11 = {
  key: 0,
  class: "badge badge-fib"
}
const _hoisted_12 = {
  key: 1,
  class: "badge badge-sta"
}
const _hoisted_13 = { class: "law-content" }
const _hoisted_14 = { class: "law-title" }
const _hoisted_15 = {
  key: 0,
  class: "law-info"
}
const _hoisted_16 = { class: "law-footer" }
const _hoisted_17 = { class: "law-penalties" }
const _hoisted_18 = { class: "penalty-item" }
const _hoisted_19 = { class: "penalty-item" }
const _hoisted_20 = {
  key: 0,
  class: "law-exclusions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "miranda-card" }, [
      _createElementVNode("div", { class: "miranda-icon" }, [
        _createElementVNode("i", { class: "bi bi-shield-lock-fill" })
      ]),
      _createElementVNode("div", { class: "miranda-text" }, [
        _createElementVNode("h4", null, "Miranda Rechte"),
        _createElementVNode("p", null, "Sie haben das Recht zu schweigen, alles was Sie sagen kann und wird vor Gericht gegen Sie verwendet werden können."),
        _createElementVNode("p", null, "Sie haben das Recht zu jeder Vernehmung Ihren Rechtsanwalt hinzuzuziehen."),
        _createElementVNode("p", null, [
          _createElementVNode("strong", null, "Haben Sie Ihre Rechte verstanden?")
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "bi bi-search" }, null, -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tmpInput) = $event)),
          type: "search",
          placeholder: "Suche nach Delikt, Paragraph oder Gesetz...",
          autocomplete: "off"
        }, null, 512), [
          [_vModelText, _ctx.tmpInput]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLaws, (category, key) => {
        return (_openBlock(), _createElementBlock("div", {
          key: key,
          class: "law-category"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", null, [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-bookmark-fill" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(category.topic), 1)
            ]),
            _createElementVNode("a", {
              href: category.lawUrl,
              target: "_blank",
              class: "law-book-link"
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "bi bi-book" }, null, -1),
              _createTextVNode(" Gesetzbuch ")
            ]), 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.paragraphs, (law) => {
              return (_openBlock(), _createElementBlock("div", {
                key: law.paragraph,
                class: "law-card"
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(law.paragraph), 1),
                  _createElementVNode("div", _hoisted_10, [
                    (law.fib)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, "FIB"))
                      : _createCommentVNode("", true),
                    (law.sta)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, "StA"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("h4", _hoisted_14, _toDisplayString(law.law), 1),
                  (law.info)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(law.info), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bi bi-cash" }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(law.displayFine) + "$", 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[5] || (_cache[5] = _createElementVNode("i", { class: "bi bi-star-fill" }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(law.wanteds) + " Wanted", 1)
                    ])
                  ]),
                  (law.exclusion || law.exclusion_info)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _cache[6] || (_cache[6] = _createElementVNode("i", { class: "bi bi-exclamation-triangle" }, null, -1)),
                        _createElementVNode("span", null, "Schließt aus: " + _toDisplayString(law.exclusion || law.exclusion_info), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}