<template>
  <div id="app-content" class="d-flex flex-column">
    <!-- Top Navbar -->
    <nav class="navbar glass-nav fixed-top">
      <div class="container-fluid">
        <!-- Brand/Logo -->
        <router-link to="/" class="navbar-brand">
          <img width="50" src="/img/statestoolbox.png" alt="States Toolbox" class="brand-image">
        </router-link>
        
        <!-- Main Navigation Items -->
        <div class="nav-links d-none d-lg-flex">
          <router-link to="/" class="nav-item" active-class="nav-item-active">
            <i class="bi bi-file-text"></i>
            <span>Bußgeldkatalog</span>
          </router-link>
          
          <router-link to="/generate" class="nav-item" active-class="nav-item-active">
            <i class="bi bi-file-plus"></i>
            <span>Akte erstellen</span>
          </router-link>
          
          <router-link to="/internal" class="nav-item nav-item-danger" active-class="nav-item-active">
            <i class="bi bi-gear"></i>
            <span>Tools & Einstellungen</span>
          </router-link>

          <a href="https://states-grand.com/" target="_blank" class="nav-item nav-item-warning">
            <i class="bi bi-box-arrow-up-right"></i>
            <span>States Panel</span>
          </a>
        </div>
        
        <!-- Status & User Info -->
        <div class="navbar-tools ms-auto d-flex align-items-center">
          <!-- Status Indicators -->
          <div class="status-group me-3">
            <div class="status-item">
              <i class="bi bi-circle-fill" 
                 :class="{'text-success': serverVersion === frontendVersion && serverVersion !== 'Offline',
                         'text-danger': serverVersion !== frontendVersion || serverVersion === 'Offline'}"></i>
              <span class="d-none d-md-inline">v{{frontendVersion}}</span>
              <span v-if="testVersion !== frontendVersion" class="version-tag d-none d-md-inline">
                {{(serverVersion !== frontendVersion && serverVersion !== 'Offline') ? '(Update required)' : 
                  (serverVersion === 'Offline') ? '(Offline)' : ''}}
              </span>
            </div>
            <div class="status-item d-none d-md-flex">
              <i class="bi bi-people-fill text-success"></i>
              <span>{{ onlineMembers }}</span>
            </div>
          </div>
          
          <!-- User Dropdown -->
          <div class="dropdown">
            <!-- <button class="user-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="user-avatar">
                <i class="bi bi-person"></i>
              </div>
              <span class="d-none d-md-inline me-1">{{ userName }}</span>
              <i class="bi bi-chevron-down"></i>
            </button> -->
            <!-- <ul class="dropdown-menu dropdown-menu-end">
              <li class="dropdown-header user-details">
                <span class="user-name">{{ userName }}</span>
                <span class="user-role">{{ org ? 'Bearbeiter' : 'Bearbeiter NR' }}</span>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#"><i class="bi bi-person-gear me-2"></i>Profil</a></li>
              <li><a class="dropdown-item" href="#"><i class="bi bi-sliders me-2"></i>Einstellungen</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#"><i class="bi bi-box-arrow-right me-2"></i>Abmelden</a></li>
            </ul> -->
          </div>
          
          <!-- Mobile Menu Toggle -->
          <button class="navbar-toggler ms-3 d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileMenu">
            <i class="bi bi-list"></i>
          </button>
        </div>
      </div>
    </nav>
    
    <!-- Mobile Offcanvas Menu -->
    <div class="offcanvas offcanvas-start d-lg-none" tabindex="-1" id="mobileMenu">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">
          <img width="40" src="/img/statestoolbox.png" alt="States Toolbox" class="me-2">
          States Toolbox
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div class="mobile-nav-menu">
          <router-link to="/" class="nav-item" active-class="nav-item-active" data-bs-dismiss="offcanvas">
            <i class="bi bi-file-text"></i>
            <span>Bußgeldkatalog</span>
          </router-link>
          
          <router-link to="/generate" class="nav-item" active-class="nav-item-active" data-bs-dismiss="offcanvas">
            <i class="bi bi-file-plus"></i>
            <span>Akte erstellen</span>
          </router-link>
          
          <router-link to="/internal" class="nav-item nav-item-danger" active-class="nav-item-active" data-bs-dismiss="offcanvas">
            <i class="bi bi-gear"></i>
            <span>Tools & Einstellungen</span>
          </router-link>

          <a href="https://states-grand.com/" target="_blank" class="nav-item nav-item-warning">
            <i class="bi bi-box-arrow-up-right"></i>
            <span>States Panel</span>
          </a>
        </div>
        
        <div class="mobile-footer mt-auto pt-3">
          <div class="status-info d-flex justify-content-between mb-3">
            <div class="status-item">
              <i class="bi bi-circle-fill" 
                 :class="{'text-success': serverVersion === frontendVersion && serverVersion !== 'Offline',
                         'text-danger': serverVersion !== frontendVersion || serverVersion === 'Offline'}"></i>
              <span>v{{frontendVersion}}</span>
            </div>
            <div class="status-item">
              <i class="bi bi-people-fill text-success"></i>
              <span>{{ onlineMembers }} Online</span>
            </div>
          </div>
          <div class="copyright">
            &copy; 2022-2024 StateRS 4 GrandRPDE03
          </div>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <router-view/>
    </div>
  </div>
</template>

<script lang="ts">
// Script remains largely unchanged
import { Options, Vue } from 'vue-class-component';
import axios from 'axios'
import { socket, state, app } from "@/socket";

@Options({
  data() {
    return {
      serverVersion: '',
      testVersion: ''
    }
  },
  computed: {
    frontendVersion() {
      return this.$store.getters.getFrontendVersion
    },
    theme() {
      return this.$store.getters.getToolboxTheme
    },    
    onlineMembers() {
      return state.count
    },
    userName() {
      return `${ ( (state.user.hasOwnProperty('org') && state.user['org'] ) ? '[' + state.user['org'] + '] ' : '' )}${state.user['userName']}`
    },
    org() {
      return state.user.hasOwnProperty('org') && state.user['org']
    },
    appName() {
      return app
    }
  },
  created() {
    this.$store.dispatch('getLawsfetch')
    axios.get(`${this.$store.getters.getServer}api/frontendVersion`)
      .then(({data}) => {
        this.serverVersion = data.frontendVersion
        this.testVersion = data.testVersion

        if(data.frontendVersion != this.frontendVersion && data.testVersion != this.frontendVersion) {
          localStorage.clear()
        }

        socket.connect();
        this.$store.dispatch('initToolboxSettings')
        return data
      })
      .catch(x => this.serverVersion = 'Offline')
  }
})
export default class App extends Vue {}
</script>

<style>
:root {
  --navbar-height: 60px;
}

#app-content {
  min-height: 100vh;
  background-color: var(--bs-body-bg);
}

/* Navbar styles */
.navbar {
  height: var(--navbar-height);
  background: var(--glass-bg);
  backdrop-filter: var(--glass-blur);
  -webkit-backdrop-filter: var(--glass-blur);
  border-bottom: 1px solid var(--glass-border);
  padding: 0 1rem;
}

.navbar-brand {
  display: flex;
  align-items: center;
  padding: 0;
}

.brand-image {
  transition: transform 0.2s ease;
}

.navbar-brand:hover .brand-image {
  transform: scale(1.05);
}

/* Navigation items */
.nav-links {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-left: 1.5rem;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  color: var(--bs-secondary-color);
  text-decoration: none;
  transition: all 0.2s ease;
  font-size: 0.6375rem;
}

.nav-item i {
  font-size: 0.85rem;
}

.nav-item:hover {
  background: rgba(255, 255, 255, 0.05);
  color: var(--bs-body-color);
}

.nav-item-active {
  background: var(--bs-primary);
  color: white !important;
}

.nav-item-danger {
  color: var(--bs-danger);
}

.nav-item-warning {
  color: var(--bs-warning);
}

/* Status indicators */
.status-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: var(--bs-secondary-color);
  font-size: 0.875rem;
}

.version-tag {
  font-size: 0.75rem;
  color: var(--bs-danger);
}

/* User dropdown */
.user-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 6px;
  padding: 0.5rem 0.75rem;
  color: var(--bs-body-color);
  transition: all 0.2s ease;
}

.user-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.user-avatar {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bs-primary);
  color: white;
  border-radius: 4px;
}

.user-details {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.user-name {
  color: var(--bs-body-color);
  font-weight: 500;
}

.user-role {
  color: var(--bs-secondary-color);
  font-size: 0.8125rem;
}

/* Mobile menu toggle */
.navbar-toggler {
  border: none;
  background: rgba(255, 255, 255, 0.05);
  color: var(--bs-body-color);
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0;
}

/* Offcanvas styles */
.offcanvas {
  background: var(--glass-bg);
  backdrop-filter: var(--glass-blur);
  -webkit-backdrop-filter: var(--glass-blur);
  border-right: 1px solid var(--glass-border);
  max-width: 280px;
}

.offcanvas-header {
  border-bottom: 1px solid var(--glass-border);
}

.mobile-nav-menu {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.mobile-footer {
  border-top: 1px solid var(--glass-border);
}

.copyright {
  text-align: center;
  color: var(--bs-secondary-color);
  font-size: 0.8125rem;
}

/* Main content */
.main-content {
  padding: 1.5rem;
  margin-top: var(--navbar-height);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    padding: 1rem;
  }
}
</style>