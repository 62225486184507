<template>
  <div id="home" class="home-container">
    <!-- Miranda Rights -->
    <div class="miranda-card">
      <div class="miranda-icon">
        <i class="bi bi-shield-lock-fill"></i>
      </div>
      <div class="miranda-text">
        <h4>Miranda Rechte</h4>
        <p>Sie haben das Recht zu schweigen, alles was Sie sagen kann und wird vor Gericht gegen Sie verwendet werden können.</p>
        <p>Sie haben das Recht zu jeder Vernehmung Ihren Rechtsanwalt hinzuzuziehen.</p>
        <p><strong>Haben Sie Ihre Rechte verstanden?</strong></p>
      </div>
    </div>

    <!-- Search Section -->
    <div class="search-section">
      <div class="search-box">
        <i class="bi bi-search"></i>
        <input 
          v-model="tmpInput" 
          type="search" 
          placeholder="Suche nach Delikt, Paragraph oder Gesetz..."
          autocomplete="off"
        >
      </div>
    </div>

    <!-- Laws List -->
    <div class="laws-list">
      <div v-for="(category, key) in getLaws" :key="key" class="law-category">
        <!-- Category Header -->
        <div class="category-header">
          <h3>
            <i class="bi bi-bookmark-fill"></i>
            {{ category.topic }}
          </h3>
          <a :href="category.lawUrl" target="_blank" class="law-book-link">
            <i class="bi bi-book"></i>
            Gesetzbuch
          </a>
        </div>

        <!-- Category Laws -->
        <div class="laws-grid">
          <div v-for="law in category.paragraphs" 
               :key="law.paragraph" 
               class="law-card">
            <!-- Law Header -->
            <div class="law-header">
              <div class="law-id">{{ law.paragraph }}</div>
              <div class="law-badges">
                <span v-if="law.fib" class="badge badge-fib">FIB</span>
                <span v-if="law.sta" class="badge badge-sta">StA</span>
              </div>
            </div>

            <!-- Law Content -->
            <div class="law-content">
              <h4 class="law-title">{{ law.law }}</h4>
              <p v-if="law.info" class="law-info">{{ law.info }}</p>
            </div>

            <!-- Law Footer -->
            <div class="law-footer">
              <div class="law-penalties">
                <div class="penalty-item">
                  <i class="bi bi-cash"></i>
                  <span>{{ law.displayFine }}$</span>
                </div>
                <div class="penalty-item">
                  <i class="bi bi-star-fill"></i>
                  <span>{{ law.wanteds }} Wanted</span>
                </div>
              </div>
              
              <div v-if="law.exclusion || law.exclusion_info" class="law-exclusions">
                <i class="bi bi-exclamation-triangle"></i>
                <span>Schließt aus: {{ law.exclusion || law.exclusion_info }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'Bußgeldkatalog',
  
  data() {
    return {
      tmpInput: '',
    };
  },

  computed: {
    ...mapGetters({
      laws: 'getLaws',
    }),
    
    getLaws(): any {
      const obj = JSON.parse(JSON.stringify(this.laws));
      const input = this.tmpInput.length > 0 ? this.tmpInput.toLowerCase() : false;

      if (!input) return obj;

      return obj.filter((x: any) => {
        const topic = x.topic.toLowerCase();
        const topicShortener = topic.indexOf(input) > -1;

        const laws = x.paragraphs.filter((y: any) => {
          const law = y.law.toLowerCase();
          const paragraph = y.paragraph.toLowerCase();

          return topicShortener || (law.indexOf(input) > -1 || paragraph.indexOf(input) > -1);
        });

        x.paragraphs = laws;

        return laws.length > 0 ? x : false;
      }).filter(Boolean); // Remove false values
    },
  },
});
</script>
<style scoped>
.home-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--bs-body-color);
}

/* Miranda Card */
.miranda-card {
  background: linear-gradient(45deg, #2c2c2c, #1a1a1a);
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.miranda-icon {
  font-size: 3rem;
  color: var(--bs-warning);
  flex-shrink: 0;
}

.miranda-text {
  flex: 1;
}

.miranda-text h4 {
  color: var(--bs-warning);
  margin-bottom: 1rem;
}

.miranda-text p {
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

/* Search Section */
.search-section {
  margin-bottom: 2rem;
}

.search-box {
  background: #2c2c2c;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.search-box i {
  color: #666;
  font-size: 1.25rem;
}

.search-box input {
  background: none;
  border: none;
  color: var(--bs-body-color);
  font-size: 1.1rem;
  width: 100%;
  padding: 0.5rem 0;
}

.search-box input:focus {
  outline: none;
}

/* Laws List */
.laws-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.law-category {
  background: #2c2c2c;
  border-radius: 16px;
  overflow: hidden;
}

.category-header {
  background: #1a1a1a;
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-header h3 {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.25rem;
}

.law-book-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--bs-info);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.2s ease;
}

.law-book-link:hover {
  background: rgba(255, 255, 255, 0.1);
}

.laws-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1.5rem;
}

.law-card {
  background: #1a1a1a;
  border-radius: 12px;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.2s ease;
}

.law-card:hover {
  transform: translateY(-2px);
}

.law-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.law-id {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--bs-primary);
}

.law-badges {
  display: flex;
  gap: 0.5rem;
}

.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
}

.badge-fib {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.badge-sta {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.law-content {
  flex: 1;
}

.law-title {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

.law-info {
  color: var(--bs-info);
  font-size: 0.875rem;
  margin: 0;
}

.law-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1rem;
}

.law-penalties {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 0.75rem;
}

.penalty-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.penalty-item:first-child {
  color: var(--bs-success);
}

.penalty-item:last-child {
  color: var(--bs-warning);
}

.law-exclusions {
  font-size: 0.875rem;
  color: var(--bs-danger);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .home-container {
    padding: 1rem;
  }

  .miranda-card {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
  }

  .laws-grid {
    grid-template-columns: 1fr;
  }

  .category-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}
</style>