import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "app-content",
  class: "d-flex flex-column"
}
const _hoisted_2 = { class: "navbar glass-nav fixed-top" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "nav-links d-none d-lg-flex" }
const _hoisted_5 = { class: "navbar-tools ms-auto d-flex align-items-center" }
const _hoisted_6 = { class: "status-group me-3" }
const _hoisted_7 = { class: "status-item" }
const _hoisted_8 = { class: "d-none d-md-inline" }
const _hoisted_9 = {
  key: 0,
  class: "version-tag d-none d-md-inline"
}
const _hoisted_10 = { class: "status-item d-none d-md-flex" }
const _hoisted_11 = {
  class: "offcanvas offcanvas-start d-lg-none",
  tabindex: "-1",
  id: "mobileMenu"
}
const _hoisted_12 = { class: "offcanvas-body" }
const _hoisted_13 = { class: "mobile-nav-menu" }
const _hoisted_14 = { class: "mobile-footer mt-auto pt-3" }
const _hoisted_15 = { class: "status-info d-flex justify-content-between mb-3" }
const _hoisted_16 = { class: "status-item" }
const _hoisted_17 = { class: "status-item" }
const _hoisted_18 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "navbar-brand"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              width: "50",
              src: "/img/statestoolbox.png",
              alt: "States Toolbox",
              class: "brand-image"
            }, null, -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "nav-item",
            "active-class": "nav-item-active"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("i", { class: "bi bi-file-text" }, null, -1),
              _createElementVNode("span", null, "Bußgeldkatalog", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/generate",
            class: "nav-item",
            "active-class": "nav-item-active"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "bi bi-file-plus" }, null, -1),
              _createElementVNode("span", null, "Akte erstellen", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/internal",
            class: "nav-item nav-item-danger",
            "active-class": "nav-item-active"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "bi bi-gear" }, null, -1),
              _createElementVNode("span", null, "Tools & Einstellungen", -1)
            ])),
            _: 1
          }),
          _cache[4] || (_cache[4] = _createElementVNode("a", {
            href: "https://states-grand.com/",
            target: "_blank",
            class: "nav-item nav-item-warning"
          }, [
            _createElementVNode("i", { class: "bi bi-box-arrow-up-right" }),
            _createElementVNode("span", null, "States Panel")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("i", {
                class: _normalizeClass(["bi bi-circle-fill", {'text-success': _ctx.serverVersion === _ctx.frontendVersion && _ctx.serverVersion !== 'Offline',
                         'text-danger': _ctx.serverVersion !== _ctx.frontendVersion || _ctx.serverVersion === 'Offline'}])
              }, null, 2),
              _createElementVNode("span", _hoisted_8, "v" + _toDisplayString(_ctx.frontendVersion), 1),
              (_ctx.testVersion !== _ctx.frontendVersion)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString((_ctx.serverVersion !== _ctx.frontendVersion && _ctx.serverVersion !== 'Offline') ? '(Update required)' : 
                  (_ctx.serverVersion === 'Offline') ? '(Offline)' : ''), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[5] || (_cache[5] = _createElementVNode("i", { class: "bi bi-people-fill text-success" }, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.onlineMembers), 1)
            ])
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "dropdown" }, null, -1)),
          _cache[7] || (_cache[7] = _createElementVNode("button", {
            class: "navbar-toggler ms-3 d-lg-none",
            type: "button",
            "data-bs-toggle": "offcanvas",
            "data-bs-target": "#mobileMenu"
          }, [
            _createElementVNode("i", { class: "bi bi-list" })
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "offcanvas-header" }, [
        _createElementVNode("h5", { class: "offcanvas-title" }, [
          _createElementVNode("img", {
            width: "40",
            src: "/img/statestoolbox.png",
            alt: "States Toolbox",
            class: "me-2"
          }),
          _createTextVNode(" States Toolbox ")
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Close"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "nav-item",
            "active-class": "nav-item-active",
            "data-bs-dismiss": "offcanvas"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("i", { class: "bi bi-file-text" }, null, -1),
              _createElementVNode("span", null, "Bußgeldkatalog", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/generate",
            class: "nav-item",
            "active-class": "nav-item-active",
            "data-bs-dismiss": "offcanvas"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("i", { class: "bi bi-file-plus" }, null, -1),
              _createElementVNode("span", null, "Akte erstellen", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/internal",
            class: "nav-item nav-item-danger",
            "active-class": "nav-item-active",
            "data-bs-dismiss": "offcanvas"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("i", { class: "bi bi-gear" }, null, -1),
              _createElementVNode("span", null, "Tools & Einstellungen", -1)
            ])),
            _: 1
          }),
          _cache[11] || (_cache[11] = _createElementVNode("a", {
            href: "https://states-grand.com/",
            target: "_blank",
            class: "nav-item nav-item-warning"
          }, [
            _createElementVNode("i", { class: "bi bi-box-arrow-up-right" }),
            _createElementVNode("span", null, "States Panel")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("i", {
                class: _normalizeClass(["bi bi-circle-fill", {'text-success': _ctx.serverVersion === _ctx.frontendVersion && _ctx.serverVersion !== 'Offline',
                         'text-danger': _ctx.serverVersion !== _ctx.frontendVersion || _ctx.serverVersion === 'Offline'}])
              }, null, 2),
              _createElementVNode("span", null, "v" + _toDisplayString(_ctx.frontendVersion), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[12] || (_cache[12] = _createElementVNode("i", { class: "bi bi-people-fill text-success" }, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.onlineMembers) + " Online", 1)
            ])
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "copyright" }, " © 2022-2024 StateRS 4 GrandRPDE03 ", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createVNode(_component_router_view)
    ])
  ]))
}