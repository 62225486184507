import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Bußgeldkatalog from '../views/Bußgeldkatalog.vue'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Bußgeldkatalog
  },  
  // {
  //   path: '/bussgeldkatalog',
  //   name: 'Bußgeldkatalog',
  //   component: Bußgeldkatalog
  // },
  {
    path: '/generate',
    name: 'Generator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Generator.vue')
  },
  {
    path: '/quicklaws',
    name: 'Quick Laws',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GeneratorSmart.vue')
  },  
  {
    path: '/generate/:code',
    name: 'Shared Code',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GeneratorSmart.vue')
  },  
  {
    path: '/userSettings',
    name: 'User Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserSettings.vue')
  },  
  {
    path: '/generateDirectory',
    name: 'Generator Directory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GeneratorDirectory.vue')
  },  
  {
  path: '/meldeamt',
  name: 'Meldeamt',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Meldeamt.vue')
} ,
{
  path: '/trafficpolice',
  name: 'Traffic Police',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/TrafficPolice.vue')
} ,
{
  path: '/changelog',
  name: 'Changelog',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Changelog.vue')
} ,
{
  path: '/login',
  name: 'Login',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
} ,
{
  path: '/internal',
  name: 'InternalMenu',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/InternalMenu.vue')
},
{
  path: '/blitzerfoto',
  name: 'Blitzerfoto',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Blitzerfoto.vue')
}  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
