import { createStore } from 'vuex'
import axios from 'axios'
import { socket, state , app } from "@/socket";


export default createStore({
  state: {
    //server: 'http://localhost:3000',
    //server: 'https://toolbox-api.state.rs/',
    server: 'https://sahpadmin.api.states-grand.com/',
    frontendVersion: '01.04.2025:1900',
    bearbeiter: null,
    gesetze: [],
    toolboxMode: "0",
    toolboxTheme: 'clss',
    userTopGesetze: [],
    fibGesetze: [],
    toolboxUser: [],
    topGesetze: [],
    commonLocation: '',
    ersatzfreiheitsstrafeVar: 25001,
    getMaximumFineVar: 150000,
    sharingIsActive: "false",
    commonDate: null,
    commonNote: '',
    commonCases: []
  },
  getters: {
    getServer: (state) => state.server,
    getLaws:(state) => state.gesetze,
    getBearbeiter: (state) => state.bearbeiter,
    getLocation: (state) => state.commonLocation,
    getDate: (state) => state.commonDate,
    getToolboxTheme: (state) => state.toolboxTheme,
    getNote: (state) => state.commonNote,
    sharingIsActive: (state) => state.sharingIsActive,
    getCases: (state) => state.commonCases,
    getToolboxUser: (state) => state.toolboxUser,
    getErsatzfreiheitsstrafeVar: (state) => state.ersatzfreiheitsstrafeVar,
    getMaximumFineVar: (state) => state.getMaximumFineVar,
    getToolboxMode: (state) => state.toolboxMode,
    getTopGesetze: (state) => {
      let tmpArry = []
      state.gesetze.map((x: any) => {

        const laws = x.paragraphs.filter((y: any) => {
          return state.topGesetze.includes(Number(y.id))
        });

        laws.map(i => {
          i.shortener = x.shortener
          return i
        })

        tmpArry = [...tmpArry, ...laws]

        return laws.length > 0 ? x : false;
      });
      
      return tmpArry
    },
    getUserTopGesetze: (state) => {
      let tmpArry = []
      state.gesetze.map((x: any) => {

        const laws = x.paragraphs.filter((y: any) => {
          return state.userTopGesetze.includes(y.id)
        });

        laws.map(i => {
          i.shortener = x.shortener
          return i
        })

        tmpArry = [...tmpArry, ...laws]

        return laws.length > 0 ? x : false;
      });
      
      return tmpArry
    },    
    getFrontendVersion: (state) => state.frontendVersion,
  },
  mutations: {
    setLaws: (state, payload) => {
      state.gesetze = payload.gesetze
      state.topGesetze = payload.topGesetze
      state.fibGesetze = payload.fibGesetze
      if(payload && payload.hasOwnProperty('ersatzfreiheitsstrafeVar') && payload.ersatzfreiheitsstrafeVar)
        state.ersatzfreiheitsstrafeVar = payload.ersatzfreiheitsstrafeVar
      if(payload && payload.hasOwnProperty('getMaximumFineVar') && payload.getMaximumFineVar)
        state.getMaximumFineVar = payload.getMaximumFineVar
    },
    setBearbeiter: (state, payload) => {
      state.bearbeiter = Object.assign({}, payload)
    },
    setSharingActive: (storeState, val) => {
      storeState.sharingIsActive = val
      localStorage.setItem('toolboxSharing', val)      
      socket.emit('changeToolboxSharing', {
        hidden: val,
        userName: state.user.userName
      })
    },
    setLocation: (state, value) => {
      state.commonLocation = value;
    },
    setDate: (state, value) => {
      state.commonDate = value;
    },
    setToolboxUsers: (state, value) => {
      state.toolboxUser = value
    },
    setToolboxUserByName: (state, data) => {
      state.toolboxUser = state.toolboxUser.map(x => {
        if(x.userName == data.userName) {
          x = data
        }

        return x
      })
    },
    setNote: (state, value) => {
      state.commonNote = value;
    },   
    setToolboxMode: (storeState, value) => {
      socket.emit('changeToolboxMode', {
        mode: value,
        userName: state.user.userName
      })

      if(value == '0') {
        storeState.userTopGesetze = []
      } else {
        storeState.userTopGesetze = storeState.fibGesetze
      }
      
      storeState.toolboxMode = value

      localStorage.setItem('toolBoxMode', value)

    },
    setToolboxTheme: (storeState, value) => {
      socket.emit('changeToolboxTheme', {
        mode: value,
        userName: state.user.userName
      })

      storeState.toolboxTheme = value

      localStorage.setItem('toolboxTheme', value)
    },        
    setCases: (state, value) => {
      state.commonCases = value;
    },     
  },
  actions: {
    getLawsfetch: ({commit, getters}) => {
      axios.get(`${getters.getServer}api/v2/data`)
      .then(x => commit('setLaws', x.data))
      .catch(x => commit('setLaws', []))
    },
    setBearbeiter: ({commit, getters}, payload) => {
      sessionStorage.setItem('bearbeiter', JSON.stringify(payload));

      commit('setBearbeiter', payload)
    },
    cleanDirectory({commit, getters}, dateVal) {
      commit('setLocation', '')
      commit('setDate', dateVal)
      commit('setNote', '')
      commit('setCases', [])
    },
    initToolboxSettings: ({commit, getters}) => {
      const theme = localStorage.getItem('toolboxTheme')
      const mode = localStorage.getItem('toolboxMode')
      const sharing = localStorage.getItem('toolboxSharing')

      if(theme) 
        commit('setToolboxTheme', theme)

      if(sharing) 
        commit('setSharingActive', sharing)

      if(mode) 
        commit('setToolboxMode', mode)
      
    },  
    getBearbeiter: ({commit, getters}) => {
      const store = sessionStorage.getItem('bearbeiter')

      if(store) {
        commit('setBearbeiter', JSON.parse(store))
      }
    }    
  },
  modules: {
  }
})
